<!--
 * @Description: 招聘列表  含无数据显示、广告banner
-->

<template>
  <div>
    <van-dialog
      v-model="dialog"
      title="个人信息授权查询"
      show-cancel-button
      :before-close="closeDialog"
    >
      <p class="msg-tip">
        我们匹配到您可能拥有如下个人信息，是否需要向相应部门申请相关数据，所查询的信息用途包括
        人才信息评估等。该服务由人才一站式服务平台提供，确认授权后，平台将获得以下信息为您服务。
      </p>
      <ul class="msg-ul">
        <li class="msg-li">学历信息</li>
        <li class="msg-li">房产信息</li>
        <li class="msg-li">户籍信息</li>
        <li class="msg-li">婚姻信息</li>
        <li class="msg-li">社保信息</li>
        <li class="msg-li">补贴信息</li>
        <li class="msg-li">人才证书信息</li>
      </ul>
      <div class="agreement">
        <van-checkbox v-model="checked">我已阅读并同意 </van-checkbox>
        <span class="link" @click="goAgreementZC">《用户服务协议》</span
        ><span class="link" @click="goAgreementYS">《隐私权政策》</span>
      </div>
    </van-dialog>

    <van-dialog v-model="dialogZC" title="用户服务协议">
      <AgreementZC />
    </van-dialog>
    <van-dialog v-model="dialogYS" title="隐私权政策">
      <AgreementYS />
    </van-dialog>
  </div>
</template>

<script>
import { set } from "vue";
import AgreementYS from "@/components/AgreementYS";
import AgreementZC from "@/components/AgreementZC";
export default {
  data() {
    return {
      checked: false,
      dialogYS: false,
      dialogZC: false,
    };
  },
  components: {
    AgreementYS,
    AgreementZC,
  },
  props: {
    loginDialogShow: {
      type: Boolean,
    },
  },

  mounted() {},
  computed: {
    dialog: {
      set(val) {
        this.$emit("update:loginDialogShow", val);
      },
      get() {
        return this.loginDialogShow;
      },
    },
  },
  methods: {
    closeDialog(action, done) {
      if (action === "confirm") {
        if (!this.checked) {
          this.$toast("请先阅读并同意《用户服务协议》《隐私权政策》");
          done(false);
          return;
        }
        this.$store.dispatch("global/authLogin").then((e) => {
          done();
        });
      } else {
        done();
      }
    },

    goAgreementZC() {
      this.dialogZC = true;
    },
    goAgreementYS() {
      this.dialogYS = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.msg-tip {
  padding: 0.3rem;
  font-size: 0.28rem;
}
.msg-ul {
  padding: 0 0.3rem;
  .msg-li {
    margin-bottom: 0.2rem;
    font-size: 0.3rem;
  }
  .msg-li::before {
    content: "";
    display: inline-block;
    margin-right: 0.2rem;
    height: 0.2rem;
    width: 0.2rem;
    border-radius: 0.2rem;
    background-color: #07c160;
  }
}
.agreement {
  padding: 0.3rem;
  font-size: 0.22rem;
  display: flex;
  align-items: center;
  .link {
    color: #1989fa;
  }
}
</style>
