<template>
  <div class="wrapper">
    <p style="color:#666">更新日期：2024年1月1日</p>
    <p style="color:#666">生效日期：2024年1月1日</p>

    <p>【导言】</p>
    <p>
      1.欢迎您使用郑州云济信息科技有限公司（以下简称“云济公司”）<u
        >&nbsp;&nbsp;&nbsp;&nbsp;人才一站式服务平台 </u
      ><u>&nbsp;&nbsp;</u
      >的服务！为使用本服务，您应当阅读并遵守《用户服务协议》（以下简称“本协议”）和《隐私权政策》。<b
        >以上协议是您与云济公司之间关于您下载、使用平台相关程序或工具，并接受相应服务的法律协议。您对本协议的接受即自愿接受全部协议的约束，请您务必审慎阅读。</b
      ><b></b>
    </p>
    <p>
      2.您可以根据自己的需求选择使用本程序提供的一项或多项具体服务并遵守其服务规则。
    </p>
    <p>
      3.“云济公司”是指云济公司和/或其相关服务可能存在的运营关联单位。“用户”是指本程序的使用者，在本协议中更多地称为“您”。成为平台相关程序或工具及本协议的尊贵用户,您需先行注册，且勾选“同意”按钮，本协议自动生效，您即应遵守本协议所有条款。
    </p>
    <p>
      4.您在使用云济公司某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”）。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。您对前述任何单独协议的接受，即视为您对本协议全部的接受。如，您对本协议的接受，即视为您对本公司《隐私权政策》的接受。<br /><b
        >1. 声明及承诺</b
      ><b></b>
    </p>
    <p>
      1.1
      <b>云济公司在此特别提醒用户</b
      >务必审慎阅读、充分理解各协议内容，特别是免除或者限制责任的协议、对您权利进行限制的协议、约定争议解决方式和司法管辖的协议等，以及开通或使用某项服务的单独协议或规则，<b
        >此类条款将以加粗的形式提示您注意。如果您同意本协议，您点击“同意并接受该协议”，或您使用本服务，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议。本协议即在您与云济公司之间产生法律效力，成为对双方均具有约束力的法律文件。阅读本协议的过程中，如果您不同意本协议或其中任何一项协议，您应立即停止使用本程序。注册或使用本程序后，您不得以未阅读本服务协议内容作任何形式的抗辩。</b
      ><b></b>
    </p>
    <p>
      1.2
      您同意，云济公司有权随时对本协议内容进行单方面的变更，并以在本网站公告的方式予以公布，无需另行单独通知您；若您在本协议内容公告变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务；<b
        >若您不同意修改后的协议内容，您应停止使用本服务。</b
      ><b></b>
    </p>
    <p>
      1.3
      <b>您保证，</b
      >您应依法具备必要、适当的权利能力和行为能力，若您是未成年人或限制民事行为能力人，则您不具备前述主体资格，您及您的监护人应承担因您的不当注册行为而导致的一切后果。<b>您保证</b>，<b
        >在您同意接受本协议并注册成为用户时，您已年满10周岁，并在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款，本协议内容不受您所属国家或地区法律的排斥。不具备前述条件的，您应立即终止注册或停止使用本服务。</b
      ><b></b>
    </p>
    <p>
      1.4未成年人使用条款：云济公司非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用本服务前，应事先取得您家长或法定监护人的书面同意。您在使用服务时，应自行判断并自行决定是否与对方进行交易或转账给对方等，且您应自行承担与此相关的所有风险。您的下载、安装、使用、帐号获取和登录等行为将视为已接受本协议所有条款及其约束。
    </p>
    <p>
      1.5云济公司是作为服务提供者，依约向您提供各类产品和服务；您的网站、应用、软件、平台等任何产品、服务及相关内容等，由您自行运营并承担全部责任。
    </p>
    <p><b>2. 权利与资质声明</b><b></b></p>
    <p>
      2.1
      云济公司对平台相关程序或程序均享有包括商标权、专利权、著作权、商业秘密等在内的全部知识产权及所有权。任何人未经云济公司书面许可，不得为任何商业或非商业目的，以任何形式对上述任一程序以及上述程序本身所包含的任一知识产权要素擅自使用，否则，云济公司将保留追究上述侵权行为法律责任的权利。
    </p>
    <p>
      2.2您于任何时间，通过本程序发表的任何内容（包括但不限于：文字、图片、视频、flash）均应被视为已授权云济公司对上述任一作品在其著作权的最大地域范围内、最长保护期限内，免费、持续、不可撤销、可再许可和独家地使用，包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权等以及《著作权法》规定的由著作权人享有的其他著作财产权利。并且，上述发表行为同样应被视为，用户已许可云济公司就任何主体涉嫌侵犯上述任一作品的行为，有权以自己名义单独提起诉讼，并获得全部赔偿。
    </p>
    <p>
      2.3您承诺，对其通过本程序上传或发布的所有内容（包括用户名、昵称、签名）均拥有合法、完整的包括著作权、商标权等在内的知识产权或已获得相关权利人的合法授权，未侵犯任何第三方的包括著作权、商标权、专利权、商业秘密等在内的知识产权及人身权等合法权益，否则，由此引发的任何诉讼、主张，您均应独立承担所有法律责任，就任何第三人针对云济公司的任何主张或诉讼，以及云济公司因此遭受的任何损失，您亦应承担包括赔偿在内的一切法律责任。
    </p>
    <p>
      2.4
      除另有特别声明外，云济公司提供本服务时所依托软件的著作权、专利权及其他知识产权均归云济公司所有。
      云济公司在本服务中所使用的商业标识，其著作权或商标权归云济公司所有。
    </p>
    <p>
      2.5
      上述及其他任何本服务包含的内容的知识产权均受到法律法规保护，未经云济公司、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
    </p>
    <p><b>3. 程序许可范围</b><b></b></p>
    <p>
      3.1
      本程序提供windows应用版本和小程序，并授予您一项的、永久性、不可转让和非排他的使用许可，以使用本程序。
    </p>
    <p>
      3.2
      本条及本协议其他条款未明示授权的其他一切权利仍归云济公司所有。除非有书面明示，云济公司不放弃相关权益。
    </p>
    <p>3.3若用户对本程序有任何意见或改进的建议，均可向云济公司提出。</p>
    <p><b>4. 权利限制</b><b></b></p>
    <p>
      4.1
      禁止反向工程、反向编译和反向汇编：用户不得对本程序进行反向工程（Reverse
      Engineer）、反向编译（Decompile）或反向汇编（Disassemble），同时不得改动编译在程序文件内部的任何资源。除法律、法规明文规定允许上述活动外，用户必须遵守此协议限制。
    </p>
    <p>
      4.2
      组件分割:本程序件是作为一个单一产品而被授予许可使用，用户不得将各个部分分开用于任何目的。
    </p>
    <p>
      4.3
      个别授权:如需进行商业性的销售、复制、分发，包括但不限于程序销售、预装、捆绑等，必须获得云济公司的书面授权和许可。
    </p>
    <p>
      4.4
      保留权利：本协议未明示授权的其他一切权利仍归云济公司所有，用户使用其他权利时必须获得云济公司的书面同意。
    </p>
    <p><b>5. 程序的安装、升级及终止</b><b></b></p>
    <p>
      5.1为提供更加优质、安全的服务，在本程序安装时云济公司可能推荐用户安装其他程序，用户可以选择安装或不安装。
    </p>
    <p>5.2 如用户不再需要使用本程序，有权利自行卸载本程序。</p>
    <p>
      5.3为了保证本程序及服务的安全性和功能的一致性，云济公司有权不经特别通知，而对本程序做不定期更新，或者对程序的部分功能效果进行改变或限制。为保证用户的使用体验，请及时下载最新版本并升级，云济公司不保证旧版程序继续可用及对应的客户服务拥有不经事先通知而修改程序功能、中断或中止程序使用的权利。
    </p>
    <p><b>6.用户使用须知</b><b></b></p>
    <p>
      6.1
      账户：当您按照注册页面提示填写信息、阅读并同意本服务协议且完成全部注册程序后，您可获得本服务的账户并成为用户。您在注册时候设置或确认的账户名及您设置的密码，将在注册成功后成为您的账户（账户名称及密码合称“账户”）。该账户是云济公司向您提供的唯一编号。您可自行设置密码，并用以查询或计量您的预付、应收或应付款。您需使用本人电子邮箱或手机号码，或者云济公司允许的其它方式，例如通过扫描二维码或识别生物特征的方式，作为登录手段登录该账户。
    </p>
    <p>6.2账户注册相关</p>
    <p>您同意：</p>
    <p>
      6.2.1
      按照要求准确提供并在取得该账户后及时更新您正确、最新及完整的身份信息及相关资料。若云济公司有合理理由怀疑您提供的身份信息及相关资料错误、不实、失效或不完整的，云济公司有权暂停或终止向您提供部分或全部服务。云济公司对此不承担任何责任，您将承担因此产生的任何直接或间接支出。若因国家法律法规、部门规章或监管机构的要求，云济公司需要您补充提供任何相关资料时，如您不能及时配合提供，云济公司有权暂停或终止向您提供部分或全部服务。
    </p>
    <p>
      6.2.2
      云济公司可能会就某些产品或服务的开通，要求您提供更多的身份资料和信息，做进一步的身份认证或资格验证，您的账户只有在通过这些认证和验证之后，方可获得使用相关产品或服务的资格。
    </p>
    <p>
      6.2.3 云济公司特别提醒您应妥善保管您的账号和密码。<b
        >当您使用完毕后，应安全退出。因您保管不善可能导致遭受被盗号或密码失窃，责任由您自行承担。您应为账号下的行为负责，所有账号下的操作行为均被视为您实施，且应由您承担全部法律后果。如果由于您未妥善保管自己的账号和密码而对您、本网站或第三方造成任何损失或损害，责任由您自行承担。由此给本网站或第三方造成损害的，您应当依法予以全额赔偿。</b
      ><b></b>
    </p>
    <p>
      6.2.4
      您需自行建立健全内部管理制度，规范对账号的使用与管理。为保障您的权益，账号应仅供您自行使用，若您基于自身经营需要等原因，将账号授权您的员工或他人管理的，须做好权限管控，并且在遇到人员变动时及时完成交接和账号安全保护（例如修改密码、变更登录方式、设置账号保护等）。<b
      ></b>
    </p>
    <p>
      <b
        >6.2.5
        实名认证是对账号归属以及责任承担等进行判断的依据，在发生账号归属争议等纠纷时，云济公司有权认定该账号归属于实名认证主体；对于归属存在争议的账号，云济公司有权暂时对该账号进行冻结。您在进行认证时务必谨慎对待，足够重视并确保认证主体与账号使用主体保持一致，避免将企业/单位使用的账号认证在个人名下，或将本人使用的账号认证在他人名下等任何不当认证行为。</b
      ><b></b>
    </p>
    <p>
      <b
        >6.2.6
        若您提供的资料存在瑕疵，导致云济公司无法核实您的身份或无法判断您的需求等，而导致云济公司未能及时处理，给您带来的损失，应由您自行承担。同时，您理解，云济公司对您的请求进行处理需要合理期限，对于我们采取措施前您已经产生的损失以及采取措施后因不可归责于我们的原因导致的损失我们不承担任何责任。</b
      ><b></b>
    </p>
    <p>
      <b
        >6.2.7
        部分第三方网站或者服务可能以微信账号等作为其登录途径之一。您知晓，除非云济公司特别说明外，这些网站或者服务并非云济公司运营，您应自行判断此类第三方网站或者服务的安全性和可用性，并自行承担相关风险和责任。</b
      ><b></b>
    </p>
    <p>
      6.2.8
      您同意，若您丧失全部或部分民事权利能力或民事行为能力，云济公司有权根据有效法律文书（包括但不限于生效的法院判决、生效的遗嘱等）或云济公司认可的其他资料处置您账户内款项。
    </p>
    <p>6.3用户信息及账户安全</p>
    <p>
      6.3.1
      云济公司非常重视用户的隐私权，将会尽最大努力保护用户的个人信息。除法律法规规定的情形外，未经用户许可云济公司不会向第三方公开、透露用户个人信息。
    </p>
    <p>
      6.3.2
      云济公司拥有完善的信息管理安全制度，为保障用户个人信息的安全，对重要用户信息采用了专业的加密存储与传输方式，以免用户信息遭受未经授权的访问、使用或披露。
    </p>
    <p>
      6.3.3用户在使用本程序的过程中，需要提供一些必要的个人信息，如手机号码、地理位置等，因产品服务与实际使用功能相关联，若用户提供的信息不完整、不真实，则可能在使用过程中受限或无法使用部分功能。
    </p>
    <p>6.3.4用户使用本程序，即视为理解并同意以下授权条款：</p>
    <p>
      6.3.5<b
        >用户授权云济公司，除法律另有规定之外，将用户本人提供给云济公司的信息、因享受云济公司服务而产生的信息（包括本协议签署之前提供和产生的信息）以及云济公司根据本条约定查询、收集的信息，用于云济公司及因服务必要而委托的合作伙伴为用户本人提供服务、推荐产品、开展市场调查与信息数据分析。</b
      ><b></b>
    </p>
    <p>
      6.3.6用户明确确认授权并许可云济公司向用户留存的邮箱、手机、地址等发送商业信息。
    </p>
    <p>
      6.3.7
      为确保用户信息的安全，云济公司对上述信息负有保密义务，并采取各种措施保证信息安全。
    </p>
    <p>6.4 帐号使用须知</p>
    <p>
      6.4.1
      帐号的所有权归云济公司所有，用户完成申请注册手续后，仅获得帐号的使用权，且该使用权仅属于首次申请注册人，同时，首次申请注册人不得赠与、借用、租用、转让或售卖帐号或者以其他方式许可非首次申请注册人使用帐号。非首次申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用账号。
    </p>
    <p>
      6.4.2用户有责任妥善保管注册帐户信息及帐户密码的安全，用户需要对本人注册帐户下的行为独立承担法律责任。用户在任何情况下均不得向他人透露帐户及密码信息，否则，由此造成的任何损失，用户均应自行承担法律责任在怀疑他人使用自己帐号时，用户应立即通知云济公司进行处理。
    </p>
    <p>
      6.4.3用户超过【365】日不使用帐号的，云济公司有权回收该帐号，并清理相关存储数据，以免造成资源浪费，由此带来的任何损失均由用户自行承担。
    </p>
    <p>
      6.4.4用户需为本人注册帐号下的一切行为独立承担负责，包括个人所发表的任何内容以及由此产生的任何后果。用户应对账号及其服务中的内容自行加以判断，并承担因使用而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。云济公司无法且不会对因前述风险而导致的任何损失或损害承担责任。
    </p>
    <p><b>7.服务中止或终止</b><b></b></p>
    <p>
      &nbsp;7.1
      为了向您提供更加完善的服务，云济公司有权定期或不定期地对服务平台或相关设备、系统、软件等进行检修、维护、升级及优化等（统称“常规维护”），如因常规维护造成云济公司服务在合理时间内中断或暂停的，云济公司无需为此向您承担责任。若因不可抗力、基础运营商过错等原因导致的非常规维护，云济公司应及时通知您。
    </p>
    <p>
      7.2
      为保证服务的安全性和稳定性，云济公司可能进行机房迁移、设备更换等重大调整，前述情况可能导致云济公司服务在合理时间内中断或暂停，云济公司无需为此向您承担责任，您应予以配合；如您不配合进行调整，或者云济公司无法与您取得联系，由此产生的后果由您自行承担。
    </p>
    <p>
      7.3
      云济公司有权根据自身运营安排，随时调整、终止部分或全部服务（包括但不限于对服务进行下线、迭代、整合等）。但是，云济公司应提前通知您，以便您做好相关数据的转移备份以及业务调整等，以保护您的合法权益。
    </p>
    <p>
      &nbsp;7.4
      在云济公司发生合并、分立、收购、资产转让时，云济公司可向第三方转让本服务下相关资产；云济公司也可在单方通知您后，将本协议下部分或全部服务及相应的权利义务转交由第三方运营或履行。具体受让主体以云济公司通知的为准。
    </p>
    <p>
      7.5
      如发生下列任何一种情形，云济公司有权不经通知而中断或终止向您提供服务且追究相应的违约责任：
    </p>
    <p>
      7.5.1根据法律法规规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
    </p>
    <p>7.5.2 您违反相关法律法规的规定或违反本协议的约定；</p>
    <p>7.5.3 按照法律法规规定，司法机关或主管部门的要求；</p>
    <p>7.5.4 出于安全的原因或其他必要的情形；</p>
    <p>
      7.5.5
      侵犯或可能侵犯云济公司的数据采集、网络爬虫或类似的数据收集以及数据摘录等行为出现。
    </p>
    <p>
      7.6
      您有责任自行备份存储在本服务中的数据。如果您的服务被终止，云济公司有权从服务器上永久地删除您的数据,法律法规另有规定的除外。服务中止或终止后，云济公司没有义务向您提供或返还数据。
    </p>
    <p>
      7.7
      如果您违反本协议约定，云济公司有权进行独立判断并采取相应措施，包括但不限于通过技术手段删除、屏蔽相关内容或断开链接等。同时，云济公司有权视用户的行为性质，采取包括但不限于暂停或终止向您提供服务，限制、中止、冻结或终止您对云济公司平台账号的使用，追究法律责任等措施。
    </p>
    <p><b>8. 免责与责任限制</b><b></b></p>
    <p>
      8.1您理解并同意，本服务是按照现有技术和条件所能达到的现状提供的。云济公司会尽最大努力保障服务的连贯性和安全性，但不能随时预见和防范法律、技术以及其他风险，我们对此类风险在法律允许的范围内免责，包括但不限于不可抗力、病毒、木马、爬虫软件、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
    </p>
    <p>
      8.2
      在适用法律许可的范围内，我们不对与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失承担赔偿责任。无论基于合同、保证、侵权或任何其他责任理论，不论任一方是否知道或应当知道上述损失或损害的可能性。
    </p>
    <p>
      8.3
      您了解并同意，您应承担因您使用本服务、违反本服务条款或在您的账户下采取的任何行动而导致的任何第三方索赔。如果由此引起云济公司及其关联公司、员工、客户和合作伙伴被第三方索赔的，您应负责处理，并赔偿云济公司及其关联公司由此遭受的全部损失和责任。
    </p>
    <p>
      8.4
      云济公司的程序可能因为下列不可抗力或意外事件无法正常运行的，我们不承担损害赔偿责任：
    </p>
    <p>
      （1）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
    </p>
    <p>
      （2）基础运营商原因，包括但不限于电信部门技术调整、电信/电力线路被他人破坏、电信/电力部门对电信网络/电力资源进行安装、改造、维护；
    </p>
    <p>（3）网络安全事故，如计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
    <p>
      （4）您通过非云济公司授权的方式使用云济公司服务，您操作不当或您的电脑软件、系统、硬件和通信线路出现故障；
    </p>
    <p>（5）经提前公告或通知的，云济公司在短时间内的系统维护；</p>
    <p>
      （6）其他非云济公司过错，云济公司无法控制或合理预见的情形。 &nbsp;&nbsp;
    </p>
    <p>
      8.5
      您在使用本程序及服务时，须自行承担如下来自云济公司不可掌控的风险内容，包括但不限于：
    </p>
    <p>8.5.1 由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险。</p>
    <p>8.5.2用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任。</p>
    <p>
      8.5.3
      由于网络信号不稳定、带宽资源不足等原因，所引起的登录失败、资料同步不完整、页面打开速度慢等风险。
    </p>
    <p>
      8.5.4
      如有需要，用户在访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担。
    </p>
    <p>
      8.6用户确认，其知悉本程序的所有功能及云济公司为实现本程序各功能所进行的必要操作，其根据自身需求自愿选择使用本程序及相关服务，因使用本程序及相关服务所存在的风险和一切后果将完全由其自己承担，云济公司不承担任何责任。
    </p>
    <p>
      8.7用户从非官方渠道使用程序，并因此受到广告信息、计算机病毒、恶意收费服务的影响及损失，均与云济公司无关。
    </p>
    <p>
      8.8
      本程序经过详细的测试，但不能保证与所有的软硬件系统完全兼容，不能保证完全没有错误。如果出现不兼容及错误的情况，用户可在将情况报告云济公司，获得技术支持。
    </p>
    <p>
      8.9
      在适用法律允许的最大范围内，对因使用或不能使用本程序所产生的损害及风险，包括但不限于直接或间接的个人损害、商业赢利的丧失、贸易中断、商业信息的丢失或任何其它经济损失，云济公司不承担任何责任。
    </p>
    <p><b>9. 行为规范</b><b></b></p>
    <p>
      9.1用户的行为不得违反《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》、《互联网用户账号名称管理规定》《全国人大常委会关于维护互联网安全的决定》、《互联网新闻信息服务管理规定》等相关法律规定，不得利用本程序及服务制作、复制、发布、传播如下侵犯其他用户或第三方合法权益、干扰本程序正常运营的内容，不得利用本程序及服务从事任何违法犯罪活动。包括但不限于：
    </p>
    <p>
      （1）
      发布、传送、传播、储存违反国家法律、危害国家安全统一、泄露国家秘密、破坏民族团结、破坏国家宗教政策、扰乱社会秩序、损害国家荣誉和利益、以非法民间组织名义的活动；
    </p>
    <p>
      （2）
      发布、传送、传播、储存违反社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽或含有任何性或性暗示的、赌博、凶杀、恐怖或者教唆犯罪的、暴力的内容；
    </p>
    <p>
      （3）
      发布、传送、传播、储存侵害他人名誉权、隐私权、肖像权、知识产权、商业秘密等合法权利的内容；
    </p>
    <p>（4） 侵犯他人隐私、个人信息或资料；</p>
    <p>（5） 发表、传送、传播骚扰、广告信息及垃圾信息；</p>
    <p>（6） 提交、发布虚假信息，或冒充、利用他人名义；</p>
    <p>（7） 虚构事实、隐瞒真相以误导、欺骗他人；</p>
    <p>
      （8）
      使用不雅或不恰当ID和昵称；涉及反动、色情的头像、签名档和发布不良内容；
    </p>
    <p>（9）发表含有猥亵、色情、人身攻击和反政府言论等非法或侵权言论的；</p>
    <p>（10） 从事非法商业活动；</p>
    <p>（11） 模仿管理人员ID或者他人ID，用以假冒管理人员或破坏管理人员形象；</p>
    <p>（12） 使用发贴机等非法程序进行违规发贴的行为；</p>
    <p>（13） 发表含有本协议禁止发布、传播内容的文章；</p>
    <p>（14） 发表不符合版面主题，或者无内容的灌水内容；</p>
    <p>（15） 同一主题内容多次出现的；</p>
    <p>（16） 违反本协议规定，发布违规广告的；</p>
    <p>（17） 发布内容或个人签名会包含有严重影响用户浏览的内容或格式的；</p>
    <p>（18） 其他云济公司认为不恰当的情况；</p>
    <p>
      （19）
      其他违反法律法规、政策及公序良俗、社会公德或干扰本程序正常运营和侵犯其他用户或第三方合法权益内容的信息。
    </p>
    <p>
      9.2用户在遵守法律及本协议的前提下使用本程序，在使用过程中不得侵犯云济公司就本程序享有的任何权利、权益，不得有下列任一行为，包括但不限于：
    </p>
    <p>（1） 删除本程序及其副本上关于著作权、商标权等知识产权的信息；</p>
    <p>
      （2）
      不得制作、发布、使用、传播用于窃取本程序帐号及他人个人信息、财产的恶意程序；
    </p>
    <p>
      （3）
      对本程序进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本程序的源代码；
    </p>
    <p>
      （4）
      对云济公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
    </p>
    <p>
      （5）
      对本程序或者本程序运行过程中释放到任何终端内存中的数据、程序运行过程中客户端与服务器端的交互数据，以及本程序运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非云济公司经授权的第三方工具/服务接入本程序和相关系统；
    </p>
    <p>
      （6）通过修改或伪造程序运行中的指令、数据，增加、删减、变动程序的功能或运行效果，或者将用于上述用途的程序、方法进行运营或向公众传播，无论这些行为是否为商业目的；
    </p>
    <p>
      （7）
      通过非云济公司开发、授权的第三方程序、插件、外挂、系统，登录或使用云济公司程序及服务，或制作、发布、传播上述工具；
    </p>
    <p>
      （8） 自行或者授权他人、第三方程序对本程序及其组件、模块、数据进行干扰；
    </p>
    <p>
      （9）
      任何用户发现本程序内的内容涉嫌侮辱或者诽谤他人、侵害他人合法权益的或违反本程序协议的，有权按投诉规则进行投诉。
    </p>
    <p>
      9.3
      您有权享受通过本程序提供的互联网技术服务和信息服务。您同时还应根据本服务条款以及您在购买具体服务时确认的相关条款和条件，履行及时付款、服务管理等责任。
    </p>
    <p>
      9.4
      您在使用本服务过程中，可以为您使用的账号设置昵称、头像、签名等信息。您应当保证这些信息的内容和形式符合法律法规（本协议中的“法律法规”指用户所属/所处地区、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法规的不时修改和补充，以及相关政策规定等，下同。）、公序良俗、社会公德以及云济公司平台规则，且不会侵害任何主体的合法权益。
    </p>
    <p><b>10. 法律责任及违约处理</b><b></b></p>
    <p>
      10.1
      如果您违反上述行为规范或本协议的任何其他约定，或者收到其他用户的举报或投诉，云济公司有权不经通知随时对相关内容进行删除，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、封禁帐号并清空所有发言，直至注销账号的处罚，
      情节严重的，还将承担相应法律责任。
    </p>
    <p>
      10.2
      因违反上述行为规范或本协议的任何其他约定，导致或产生任何第三方主张的任何索赔、要求，您均应独立承担责任；任何第三人针对云济公司的任何主张或诉讼，以及云济公司因此遭受的任何损失，您亦应承担包括赔偿在内的一切法律责任。
    </p>
    <p>
      10.3
      云济公司有权依合理判断对其认为确已违反有关法律法规或本协议规定的用户进行处罚，对违法违规的任何用户采取包括但不限于中断使用许可、停止提供服务、限制使用、追究法律责任等措施，以及依据法律法规保存有关信息并向有关部门报告等，因此产生的一切法律责任，用户均应独自承担。
    </p>
    <p>
      10.4
      如因本程序所使用的第三方程序或技术引发任何纠纷，应由该第三方负责解决并独立承担所有法律责任，云济公司不承担任何责任。云济公司不对第三方程序或技术提供客服支持，若您需要获取支持，请自行与第三方联系。
    </p>
    <p><b>11. 其他注意事项</b><b></b></p>
    <p>
      11.1您在使用本程序某一特定服务时（如视频服务），该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），用户在使用该项服务前请仔细阅读并同意相关的单独协议。
    </p>
    <p>
      11.2
      我们非常重视对您个人信息的保护，我们将会尽力保障您在本程序及服务中的数据存储安全，但是，我们并不能完全保证所有用户数据完整，对于可能出现的存储失败、或服务终止、数据被删除等情况，我们没有义务向您提供或返还任何数据，也无需对您因上述情形而遭受的任何损失承担包括赔偿在内的任何法律责任。
    </p>
    <p>
      11.3
      云济公司有权不定期更新本协议条款，一旦本协议的内容发生变动，云济公司将会通过适当方式提示用户修改内容。
    </p>
    <p>
      11.4鉴于市场变化、业务规模调整、网络服务的及时性、复杂性、高效性等特性及监管要求、政策调整等原因，您同意云济公司可以不定时对本协议以及云济公司的相关服务规则进行调整，修改，并通过网站公告、邮件通知、短信通知、系统消息、站内信等方式中的一种或多种予以公布。<b
        >经修订的协议、规则、政策一经公布，则立即生效且成为本服务协议不可分割的一部分。若您在调整后继续使用云济公司服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的内容。</b
      ><b></b>
    </p>
    <p>
      11.5本协议的成立、生效、履行、解释及纠纷解决，均适用中华人民共和国大陆地区法律（不包括冲突法）。所有与本合同有关的争议应通过甲乙双方友好协商解决。如合同双方经协商仍未能解决争议，可将争议提交至郑州仲裁委员会进行仲裁。仲裁裁决为终局裁决，对双方均有约束力。由该仲裁产生的一切相关费用均由败诉方承担。
    </p>
    <p>
      11.6
      除争议事项外，各方应继续行使各自在本合同项下的其他权利，履行各自在本合同项下的其他义务。
    </p>
    <p>
      11.7
      如本协议中的任何条款因任何原因被判定为完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有执行力。
    </p>
    <p>
      11.8
      本协议所有协议的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
    </p>
    <p>
      11.9
      除另有约定外，出于服务专业性考虑，云济公司亦可能委托关联公司或其他法律主体向您提供云济公司一项或多项具体服务，同时您可能会与该等公司订立相关条款或条件，请您审慎阅读。
    </p>
    <p>
      11.10 云济公司在法律允许最大范围对本协议拥有解释权与修改权。&nbsp;&nbsp;
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.wrapper {
  overflow-y: scroll;
  padding: 0 0.3rem;
  height: 400px;
}
</style>